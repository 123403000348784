import React from 'react'
import Image from './Image'
export default () => (
<section className="section">
  <div className="container">
    <div className="PostSection">
      <div className="PostSection--GridJK">
        <div className="PostCard--HomeP PostCard--Image relative"><Image background title="Flytthjälp i Göteborg" src="/images/flytthjalp.webp" alt="Flytthjälp i Göteborg" /></div>
        <div>
            <h3>Flytthjälp</h3>
            <p>Vi tillhandahåller effektiv och pålitlig flytthjälp som gör din flytt både enkel och utan stress. 
              Oavsett om det gäller en flytt för privatpersoner eller företag, har våra erfarna samarbetspartners kapaciteten att ta hand om allt från packning och transport till montering och uppackning. 
              Vi prioriterar alltid våra kunders behov och säkerställer en trygg och smidig flyttupplevelse.<br></br>

            <a href="/#bokaoss">Kontakta oss idag för en offert</a> och låt oss hjälpa dig till en bekymmersfri start i ditt nya hem eller kontor!</p>
        </div>
      </div>
    </div>
  </div>
</section>

)